import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "option",
    "current",
    "oneTime",
    "oneTimeFormField",
    "currentHolder",
    "oneTimeHolder"
  ];

  connect() {
    // set up default
    this.handleLoad("current_availability");
  }

  handleSelection(event) {
    this.processLocationType(event.currentTarget.getAttribute("for"));
  }

  handleLoad(location) {
    this.processLocationType(location);
  }

  processLocationType(location) {
    this.clearPreviousChoice();
    switch (location) {
      case "current_availability":
        this.handleCurrent();
        break;
      case "one_time":
        this.handleOneTime();
        break;
      default:
        this.handleCurrent();
        break;
    }
  }

  handleCurrent() {
    this.oneTimeFormFieldTarget.value = false;
    this.currentTarget.classList.add("active");
    this.currentHolderTarget.style.display = "block";
    this.oneTimeHolderTarget.style.display = "none";
  }

  handleOneTime() {
    this.oneTimeFormFieldTarget.value = true;
    this.oneTimeTarget.classList.add("active");
    this.currentHolderTarget.style.display = "none";
    this.oneTimeHolderTarget.style.display = "block";
  }

  clearPreviousChoice() {
    this.optionTargets.forEach((option) => {
      option.classList.remove("active");
    });
  }
}
